<template>
  <div class="login-page">
    <div style="padding-top:3rem;">
      <van-image
          width="2.5rem"
          height="2.5rem"
          src="/assets/lw-logo.png"
      />
      <div style="margin-top:1.5rem;"></div>
      <!-- vant表单 -->
      <van-form class="login" style="width: 9rem;margin:0px auto;">
        <van-field
            v-model="state.username"
            name="账号"
            placeholder="账号"
            type="number"
            :rules="[{ required: true, message: '请填写用户名称' }]"
            autoComplete="off"
        />
        <van-field
            type="text" 
            value="" 
            style=" opacity: 0; width: 0;height: 0;"
        />
        <van-field
            v-model="state.password"
            :type="state.isPassword"
            name="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' },{pattern: /^\w{6,}$/,message:'密码不少于6位'}]"
        >
          <!--        <template #right-icon>-->
          <!--          <span @click="onPassword" style="font-size: 20px;" class="iconfont icon-view"></span>-->
          <!--        </template>-->
        </van-field>
        <div style="margin-top:1.3rem;"></div>
        <div style="margin: 16px;">
          <van-button @click="onSubmit" style="border-radius: 4px;font-size: 18px;"  block type="danger" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
      <div style="margin-top:0.7rem;"></div>
      <div style="margin:0 auto;width: 9.5rem;float: contour;color: #BEBEBE;font-size: 0.35rem;font-weight: 400;">
        <div >如您还没有账号，请联系市场工作人员</div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import { Login } from "../../api/login"

export default {
  setup() {
    const state = reactive({
      username: '',
      password: '',
      isPassword:'password', //密码的type类型
      isText:true
    });
    const onSubmit = async() =>{
      Login({username:state.username,password:state.password})
          .then(function (res){
              if(res.Code===200){
                let user = res.Data;
                localStorage.setItem('user',JSON.stringify(res.Data))
                localStorage.setItem('user_token',user.user_token)
                window.location.href = '/wechat/user?state='+user.id;
              }
          }).catch(function (err){
            console.log(err)
            console.log(111)
          })
    }
    return {
      state,
      onSubmit
    };
  }
};
</script>

<style lang="less" scoped>
#app{
  background-color: unset;
}
.login-page{
  height:100vh;
  background-color:#fff;
  :deep(.van-button--danger){
    background-color: #ff552e;
  }
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}

</style>